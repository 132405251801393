import React from 'react';
import { Link } from 'gatsby';
import mobileLogo from '../../assets/images/global/mobile-logo.svg';
import desktopLogo from '../../assets/images/global/desktop-logo.svg';
import './GlobalHeader.scss';

export default function GlobalHeader() {
  return (
    <div className="global-header">
      <div className="mobile-logo">
        <Link to="/">
          <img alt="Big Tex Hog Tech Blog" src={mobileLogo} />
        </Link>
      </div>
      <div className="desktop-logo">
        <Link to="/">
          <img alt="Big Tex Hog Tech Blog" src={desktopLogo} />
        </Link>
      </div>
    </div>
  );
}
