/* eslint-disable react/jsx-props-no-spreading */
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/command-line/prism-command-line.css';
// Overrides for prism are found in global.scss
import './src/assets/stylesheets/global.scss';
import React from 'react';
import Layout from './src/components/Layout/Layout';

// If I want a global page layout... then make a layout component
export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

// Do I need to implement some global context?
// This might be how I do it.
// export function wrapRootElement({ element }) {
//   return <OrderProvider>{element}</OrderProvider>;
// }
