// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affirmations-js": () => import("./../../../src/pages/affirmations.js" /* webpackChunkName: "component---src-pages-affirmations-js" */),
  "component---src-pages-babylon-skull-poc-js": () => import("./../../../src/pages/babylon-skull-poc.js" /* webpackChunkName: "component---src-pages-babylon-skull-poc-js" */),
  "component---src-pages-babylon-xr-basics-js": () => import("./../../../src/pages/babylon-xr-basics.js" /* webpackChunkName: "component---src-pages-babylon-xr-basics-js" */),
  "component---src-pages-babylonjs-hand-inserted-js": () => import("./../../../src/pages/babylonjs-hand-inserted.js" /* webpackChunkName: "component---src-pages-babylonjs-hand-inserted-js" */),
  "component---src-pages-generator-js": () => import("./../../../src/pages/generator.js" /* webpackChunkName: "component---src-pages-generator-js" */),
  "component---src-pages-longslice-vr-js": () => import("./../../../src/pages/longslice-vr.js" /* webpackChunkName: "component---src-pages-longslice-vr-js" */),
  "component---src-pages-tabletopics-js": () => import("./../../../src/pages/tabletopics.js" /* webpackChunkName: "component---src-pages-tabletopics-js" */),
  "component---src-pages-talkaboutit-js": () => import("./../../../src/pages/talkaboutit.js" /* webpackChunkName: "component---src-pages-talkaboutit-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/Author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

