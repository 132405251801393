import PropTypes from 'prop-types';
import React from 'react';
import GlobalHeader from '../GlobalHeader/GlobalHeader';

export default function Layout({ children }) {
  return (
    <div className="global-layout">
      <GlobalHeader />
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};
